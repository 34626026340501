
html, body, #root {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100vw;
  overscroll-behavior:none;
  /*-webkit-user-select: none; /* Safari */
  /*-ms-user-select: none; /* IE 10 and IE 11 */
  /*user-select: none; /* Standard syntax */
  touch-action: pan-x pan-y;
  padding: 0;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: darkgray;
  border-radius: 15px;
}

iframe {
  width: 100vw;
  height: 100vh;
  border: none;
  overflow: hidden;
  display: block;
}